.card-container {
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: space-between;
  margin-top: 5%;
}

.cards {
  width: calc(48% - 20px);
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.key-heading {
  margin-top: 4%;
  line-height: 1.4;
  color: hsl(220, 48%, 28%);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

.user-info {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

#icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}

#icon {
  color: #3abef9;
  font-size: 30px;
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.card-content {
  line-height: 1.8;
  text-align: justify;
  color: #848383;
  font-size: 14px;
}

@media (max-width: 768px) {
  .cards {
    width: calc(100% - 20px);
    margin: 10px 0;
  }

  .card-content {
    font-size: 12px;
  }

  .card-title {
    font-size: 16px;
  }

  #icon {
    font-size: 25px;
  }
}

@media (max-width: 480px) {
  .card-content {
    font-size: 11px;
  }

  .card-title {
    font-size: 14px;
  }

  #icon {
    font-size: 20px;
  }

  .key-heading {
    text-align: center;
    font-size: 22px;
  }
}
