body {
  min-height: 100vh;
  font-family: "Heebo", sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

footer {
  margin-top: 2%;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 80px;
  background-color: #ffffff;
}

.Footercontainer {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 12px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer-widget {
  width: calc(20% - 30px);
  margin: 0 15px 50px;
  padding: 0 12px;
}

.footer-widget:nth-child(1) {
  width: calc(40% - 50px);
  margin-right: 15px;
}

.footer-widget .logo {
  margin-bottom: 30px;
  width: 210px;
  vertical-align: middle;
}

.footer-widget .iatalogo {
  margin-bottom: 30px;
  vertical-align: middle;
  width: 90px;
}
.footer-widget p {
  color: #848383;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 24px;
}

.footer-widget .socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-widget .socials li {
  list-style: none;
}

.footer-widget .socials li a {
  width: 44px;
  height: 44px;
  margin-right: 10px;
  color: #ffffff;
  background-color: rgba(3, 127, 243, 0.5);
  border-radius: 50%;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}

.footer-widget .socials li a:hover {
  transform: scale(1.2);
  background-color: #037ef3;
}

.footer-widget h6 {
  color: hsl(220, 48%, 28%);
  margin: 10px 0 35px;
  font-size: 20px;
  font-weight: 600;
}

.footer-widget .links li {
  list-style: none;
}

.footer-widget .links li a {
  color: #848383;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  line-height: 32px;
  transition: all 0.3s ease-out;
}

.footer-widget .links li a:hover {
  color: #037ef3;
}

.copyright-wrapper {
  position: relative;
  padding: 20px 0;
  border-top: 1px solid rgba(88, 89, 120, 0.4);
}

.copyright-wrapper p {
  color: rgba(88, 89, 120, 0.6);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  position: relative;
  text-align: center;
}

.typing-text::after {
  content: "|";
  display: inline-block;
  width: 0;
  animation: typing-animation 2s steps(14);
}

@keyframes typing-animation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.copyright-wrapper p a {
  position: relative;
  left: 0.5%;
  color: inherit;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.copyright-wrapper p a:hover {
  color: #037ef3;
}

.copyright-wrapper p a {
  color: #037ef3;
}

@media (max-width: 992px) {
  .container {
    max-width: 960px;
  }

  .footer-widget,
  .footer-widget:nth-child(1) {
    width: calc(50% - 30px);
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 720px;
  }

  .footer-widget,
  .footer-widget:nth-child(1) {
    width: 100%;
    margin: 0 10px 50px;
  }
}
