.about {
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.about-left {
  flex-basis: 45%;
}

.about-right {
  flex-basis: 45%;
  position: relative;
}

.about-img {
  width: 100%;
  border-radius: 10px;
}

.play_icon {
  width: 60px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about h3 {
  font-size: 35px;
  font-weight: 600;
  color: hsl(220, 48%, 28%);
}

/* .about h3,
.line {
  animation: autoShow both;
  animation-timeline: view(70% 8%);
}
@keyframes autoShow {
  from {
    opacity: 0;
    transform: translateY(200px) scale(0.2);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
} */
.about h2 {
  font-size: 33px;
  font-weight: 500;
  margin: 10px 0;
  max-width: 350px;
}

.about p {
  margin-top: 5%;
  line-height: 1.8;
  color: #848383;
  text-align: justify;
  /* animation: autoShow both;
  animation-timeline: view(70% 8%); */
}

.about-content {
  font-size: 15px;
  color: hsl(220, 68%, 54%);
  font-family: "Inter", sans-serif;
}

.a-link {
  font-size: 17px;
  color: hsl(220, 68%, 54%);
}

.Rightarrow {
  margin-left: 2%;
  animation: moveRight 1.5s ease infinite;
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

.line {
  position: relative;
}

.line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 2px;
  width: 13%;
  height: 4px;
  background: linear-gradient(to right, #007bff, #253c6a);
  margin-bottom: 7px;
}

.btn-Home {
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin-top: 7%;
  background-color: #3d63ea;
}

.btn-Home:hover {
  transform: scale(1.09);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

@media (max-width: 800px) {
  .about {
    flex-direction: column;
  }

  .about-left,
  .about-right {
    flex-basis: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 576px) {
  .about-left,
  .about-right {
    margin: 0;
  }
  .line {
    margin-top: 8%;
  }
  .about h3 {
    font-size: 28px;
  }

  .about p {
    font-size: 14px;
  }

  .a-link {
    font-size: 15px;
  }
}
