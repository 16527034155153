.Features {
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  justify-content: space-between;
  margin-top: 4%;
}
.headings {
  font-size: 18px;
  color: #0e33cb;
  margin-bottom: 1%;
}
.feat-content {
  margin-top: 5%;
}
.feat-content h2 {
  font-size: 30px;
  color: hsl(220, 48%, 28%);
  font-family: 500;
  margin-bottom: 1%;
}
.feat-content p {
  font-size: 16px;
  color: #848383;
  line-height: 1.8;
  text-align: justify;
}
.features-left {
  flex-basis: 55%;
  display: flex;
  flex-wrap: wrap;
}

.carding {
  width: 45%;
  padding: 12px;
  padding-right: 25px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.card-icon {
  font-size: 24px;
  margin-bottom: 10px;
}
.icons {
  background: hsl(220, 68%, 54%);
  width: 30px;
  color: #fff;
  padding: 3%;
  border-radius: 5px;
}
.features-right {
  flex-basis: 45%;
  padding: 3%;
  border-radius: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features-right img {
  width: 100%;
  height: auto;
}
.card-content h2 {
  color: hsl(220, 48%, 28%);
  font-size: 18px;
  margin-bottom: 3%;
}
.card-content p {
  font-size: 14px;
  color: #848383;
  line-height: 1.6;
  text-align: justify;
}
.carding:hover {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-5px, 0);
  }
  50% {
    transform: translate(5px, 0);
  }
  75% {
    transform: translate(-5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@media (max-width: 768px) {
  .Features {
    flex-direction: column;
    align-items: center;
  }

  .feat-content {
    margin-top: 25%;
  }

  .feat-content h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .feat-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .features-left {
    width: 100%;
  }

  .features-right {
    width: 100%;
    margin-top: 20px;
  }

  .carding {
    width: 90%;
    padding: 15px;
    margin-bottom: 20px;
  }

  .icons {
    padding: 12px;
  }
}
