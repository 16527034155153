@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
  --header-height: 3.5rem;
  --first-color: hsl(220, 68%, 54%);
  --first-color-lighten: hsl(220, 68%, 97%);
  --title-color: hsl(220, 48%, 28%);
  --text-color: hsl(220, 12%, 45%);
  --body-color: hsl(220, 100%, 99%);
  --body-font: "Poppins", sans-serif;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  --font-medium: 500;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1120px;
  margin-inline: 1.5rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 8px hsla(220, 68%, 12%, 0.1);
  background-color: var(--body-color);
  z-index: var(--z-fixed);
}

.nav {
  height: var(--header-height);
}

.nav__data {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.3s;
}

.nav__logo img {
  width: 140px;
}

.nav__logo i {
  font-size: 1.25rem;
}

.nav__logo:hover {
  color: var(--first-color);
}

.nav__toggle {
  position: relative;
  width: 32px;
  height: 32px;
}

.nav__toggle-menu,
.nav__toggle-close {
  font-size: 1.25rem;
  color: var(--title-color);
  position: absolute;
  display: grid;
  place-items: center;
  inset: 0;
  cursor: pointer;
  transition: opacity 0.1s, transform 0.4s;
}

.nav__toggle-close {
  opacity: 0;
}

@media screen and (max-width: 1118px) {
  .nav__menu {
    background-color: var(--body-color);
    position: absolute;
    left: 0;
    top: 2.5rem;
    width: 100%;
    height: calc(100vh - 3.5rem);
    overflow: auto;
    padding-block: 1.5rem 4rem;
    pointer-events: none;
    opacity: 0;
    transition: top 0.4s, opacity 0.3s;
  }
  .nav__menu::-webkit-scrollbar {
    width: 0.5rem;
  }
  .nav__menu::-webkit-scrollbar-thumb {
    background-color: hsl(220, 12%, 70%);
  }
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

.nav__link:hover {
  background-color: var(--first-color-lighten);
}

.show-menu {
  opacity: 1;
  top: 3.5rem;
  pointer-events: initial;
}

.show-icon .nav__toggle-menu {
  opacity: 0;
  transform: rotate(90deg);
}

.show-icon .nav__toggle-close {
  opacity: 1;
  transform: rotate(90deg);
}

.dropdown__button {
  cursor: pointer;
}

.dropdown__arrow {
  font-size: 1.5rem;
  font-weight: initial;
  transition: transform 0.4s;
}

.dropdown__content,
.dropdown__group,
.dropdown__list {
  display: grid;
}

.dropdown__container {
  background-color: var(--first-color-lighten);
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease, opacity 0.4s ease;
}

.dropdown__content {
  row-gap: 1.75rem;
}

.dropdown__group {
  padding-left: 2.5rem;
  row-gap: 0.5rem;
}

.dropdown__group:first-child {
  margin-top: 1.25rem;
}

.dropdown__group:last-child {
  margin-bottom: 1.25rem;
}

.dropdown__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.dropdown__list {
  row-gap: 0.25rem;
}

.dropdown__link {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  color: var(--text-color);
  transition: color 0.3s, background-color 0.3s;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: left;
  align-items: start;
}

.dropdown__link:hover {
  color: var(--first-color);
  background-color: var(--first-color-lighten); /* Adjust as needed */
  border-radius: 5px; /* Optional: Adds rounded corners */
}

.rotate {
  transform: rotate(180deg);
}

.show-dropdown {
  height: auto;
  opacity: 1;
}

@media screen and (min-width: 1118px) {
  .nav {
    height: calc(var(--header-height) + 2rem);
    display: flex;
    justify-content: space-between;
  }
  .nav__toggle {
    display: none;
  }
  .nav__list {
    display: flex;
    column-gap: 3rem;
    height: 100%;
  }
  .nav li {
    display: flex;
    align-items: center;
    position: relative;
  }
  .nav__link {
    padding: 0;
    cursor: pointer;
  }
  .nav__link:hover {
    background-color: initial;
  }
  .dropdown__button {
    column-gap: 0.25rem;
    pointer-events: initial;
  }
  .dropdown__container {
    position: absolute;
    width: 207px;
    line-height: 1.6;
    top: 100%;
    left: -40px;
    right: auto;
    background-color: var(--body-color);
    box-shadow: 0 6px 8px hsla(220, 68%, 12%, 0.05);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }
  .dropdown__content {
    padding: 1rem;
  }
  .dropdown__group {
    padding: 0;
    row-gap: 0.5rem;
  }
  .dropdown__group:first-child,
  .dropdown__group:last-child {
    margin: 0;
  }
  .dropdown__list {
    row-gap: 0.5rem;
  }
  .dropdown__link {
    font-size: var(--normal-font-size);
  }
  .dropdown__link:hover {
    color: var(--first-color);
  }
  .dropdown__item {
    cursor: pointer;
  }
  .dropdown__item:hover > .dropdown__container {
    opacity: 1;
    pointer-events: initial;
  }
}

@media screen and (min-width: 1152px) {
  .container {
    margin-inline: auto;
  }
}

@media only screen and (max-width: 768px) {
  .nav__logo img {
    width: 80px;
  }
  .last-li {
    margin-top: 5%;
    position: relative;
    left: 5%;
  }
}
