/* .seaFrieght-container {
  background: url("../../../assets/BG.png");
  background-position: center;
  height: 100vh;
  display: flex;
  background-size: cover;
}
.whole-sea-container {
  padding-left: 5%;
  margin-top: 3%;
  padding-right: 5%;
  display: flex;
  max-width: 1200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.seaFrieght-Left {
  flex: 1;
  padding: 20px;
}
.seaFrieght-Right {
  flex: 1;
  padding: 20px;
}
.seaFrieght-Left h3 {
  font-size: 33px;
  font-weight: 800;
  line-height: 1.4;
  margin-bottom: 1%;
}
.seaFrieght-Left h5 {
  line-height: 1.4;
  color: #3abef9;
  margin-bottom: 2%;
}
.seaFrieght-Left p,
.seaFrieght-Right p,
.text-content p {
  color: #848383;
  line-height: 1.8;
  margin-top: 3%;
  text-align: justify;
}
.seaFrieght-Right img {
  max-width: 80%;
  height: auto;
  position: relative;
  left: 20%;
}

.seaFrieght-Img-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-left: 6%;
  padding-right: 5%;
  margin-top: 8%;
  font-family: Arial, sans-serif;
}

.images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.image:nth-child(2) {
  margin-top: 30%;
}
.image:nth-child(3) {
  margin-top: -60px;
}
.text-content {
  margin-top: 8%;
  padding-left: 6%;
  max-width: 55%;
  align-items: center;
}

.text-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.paragh p {
  color: #848383;
  line-height: 1.8;
  text-align: justify;
  margin-top: 3%;
  padding-left: 8%;
  padding-right: 9%;
} */

.seaFrieght-container {
  background: url("../../../assets/BG.png");
  background-position: center;
  height: 100vh;
  display: flex;
  background-size: cover;
}
.whole-sea-container {
  padding-left: 5%;
  margin-top: 3%;
  padding-right: 5%;
  display: flex;
  max-width: 1200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.seaFrieght-Left {
  flex: 1;
  padding: 20px;
}
.seaFrieght-Right {
  flex: 1;
  padding: 20px;
}
.seaFrieght-Left h3 {
  font-size: 33px;
  font-weight: 800;
  line-height: 1.4;
  margin-bottom: 1%;
}
.seaFrieght-Left h5 {
  line-height: 1.4;
  color: #3abef9;
  margin-bottom: 2%;
}
.seaFrieght-Left p,
.seaFrieght-Right p,
.text-content p {
  color: #848383;
  line-height: 1.8;
  margin-top: 3%;
  text-align: justify;
}
.seaFrieght-Right img {
  max-width: 80%;
  height: auto;
  position: relative;
  left: 20%;
}
.seaFrieght-Img-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-left: 6%;
  padding-right: 5%;
  margin-top: 8%;
  font-family: Arial, sans-serif;
}
.images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.image:nth-child(2) {
  margin-top: 30%;
}
.image:nth-child(3) {
  margin-top: -60px;
}
.text-content {
  margin-top: 8%;
  padding-left: 6%;
  max-width: 55%;
  align-items: center;
}
.text-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
}
.paragh p {
  color: #848383;
  line-height: 1.8;
  text-align: justify;
  margin-top: 3%;
  padding-left: 8%;
  padding-right: 9%;
}

@media only screen and (max-width: 768px) {
  .whole-sea-container {
    margin: 70px auto;
    flex-direction: column;
    align-items: center;
  }
  .seaFrieght-Right img {
    position: relative;
    left: 0;
    max-width: 100%;
  }
  .seaFrieght-Img-container {
    flex-direction: column;
  }
  .text-content {
    max-width: 100%;
    padding-left: 0;
  }
  .images {
    grid-template-columns: 1fr;
  }
  .image:nth-child(2),
  .image:nth-child(3) {
    margin-top: 0;
  }
  .seaFrieght-Left h3 {
    font-size: 24px;
  }
  .text-content h2 {
    font-size: 20px;
  }
  .paragh p {
    padding-left: 5%;
    padding-right: 5%;
  }
}
