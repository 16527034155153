/* .contact {
  margin: 120px auto;
  background-color: #f5f7f8;
  padding: 5%;
  max-width: 90%;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.dark-btn {
  width: 30%;
  padding: 2%;
}
.dark-btn:hover {
  transform: scale(1.1);
}
.contact-col {
  flex-basis: 46%;
  color: #003973;
}
.contact-col h1 {
  font-weight: 700;
  font-size: 35px;
  margin-top: -12%;
}
.contact-col h3 {
  font-weight: 700;
  display: flex;
  margin-top: 1%;
  margin-right: 3%;
}
.contact-col h2 {
  font-weight: 700;
  margin-bottom: 5%;
  font-size: 30px;
}
.contact-col p {
  margin-top: 3%;
  font-family: "Cabin", sans-serif;
  line-height: 1.7;
  font-size: 17px;
  text-align: justify;
  margin-left: 2%;
}
.contact-col ul {
  position: relative;
  left: 6%;
}
.contact-col ul li {
  font-family: "Cabin", sans-serif;
  display: flex;
  align-items: center;
  margin: 35px 0;
}
.contact-icon {
  border: transparent;
  border-radius: 50%;
  background: #e9eef7;
  padding: 5%;
  font-size: 20px;
  margin-right: 15px;
}
.contact form label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 2%;
}
.contact form input,
.contact form textarea {
  display: block;
  width: 100%;
  border-radius: 10px;
  background: #edf0f3;
  padding: 15px;
  border: 0;
  outline: 0;
  margin-top: 5px;
  margin-bottom: 25px;
  resize: none;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
}

.input-row {
  flex: 0 0 50%;
  padding-right: 10px;
}

.input-row label {
  display: block;
  margin-bottom: 5px;
}

.input-row input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.cont-heading {
  font-size: 15px;
  color: #0e33cb;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  margin-bottom: -4%;
  justify-content: center;
}

.contact-col iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 700px) {
  .contact {
    display: block;
  }
  .contact-col {
    padding: 20px;
  }
} */

/* ContactUs.css */

.contact {
  margin: 120px auto;
  background-color: #f5f7f8;
  padding: 5%;
  max-width: 90%;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex-wrap: wrap;
}

.contact-col {
  flex-basis: 46%;
  color: #003973;
  padding: 20px;
}

.contact-col h1 {
  font-weight: 700;
  font-size: 35px;
  margin-top: -12%;
}

.contact-col h3 {
  font-weight: 700;
  display: flex;
  margin-top: 1%;
  margin-right: 3%;
}

.contact-col h2 {
  font-weight: 700;
  margin-bottom: 5%;
  font-size: 30px;
}

.contact-col p {
  margin-top: 3%;
  font-family: "Cabin", sans-serif;
  line-height: 1.7;
  font-size: 17px;
  text-align: justify;
  margin-left: 2%;
}

.contact-col ul {
  position: relative;
  left: 6%;
}

.contact-col ul li {
  font-family: "Cabin", sans-serif;
  display: flex;
  align-items: center;
  margin: 35px 0;
}

.contact-icon {
  border: transparent;
  border-radius: 50%;
  background: #e9eef7;
  padding: 5%;
  font-size: 20px;
  margin-right: 15px;
}

.contact form label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 2%;
}

.contact form input,
.contact form textarea {
  display: block;
  width: 100%;
  border-radius: 10px;
  background: #edf0f3;
  padding: 15px;
  border: 0;
  outline: 0;
  margin-top: 5px;
  margin-bottom: 25px;
  resize: none;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
}

.input-row {
  flex: 0 0 50%;
  padding-right: 10px;
}

.input-row label {
  display: block;
  margin-bottom: 5px;
}

.input-row input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.cont-heading {
  font-size: 15px;
  color: #0e33cb;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  margin-bottom: -4%;
  justify-content: center;
}

.contact-col iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.error {
  color: red;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .contact {
    flex-direction: column;
    align-items: center;
  }
  .contact-col {
    width: 90%;
    margin-bottom: 20px;
  }
  .input-row {
    flex: 0 0 100%;
    padding-right: 0;
  }
  .contact-col iframe {
    height: 40vh;
  }
}

@media (max-width: 768px) {
  .contact {
    padding: 10px;
  }
  .contact-col h1 {
    font-size: 28px;
  }
  .contact-col h2 {
    font-size: 24px;
  }
  .contact form input,
  .contact form textarea {
    padding: 10px;
  }
  .contact-col iframe {
    height: 40vh;
  }
}

@media (max-width: 480px) {
  .contact {
    margin: 80px auto;
    padding: 10px;
  }
  .contact-col h1 {
    font-size: 22px;
  }
  .contact-col h2 {
    font-size: 18px;
  }
  .contact form input,
  .contact form textarea {
    padding: 8px;
  }
  .contact-col iframe {
    height: 40vh;
  }
}
