.WareHouse-container {
  background: url("../../../assets/BG.png");
  background-position: center;
  height: 100vh;
  display: flex;
  background-size: cover;
  padding-right: 5%;
  padding-left: 5%;
  width: 100%;
}

.WareHose-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-image {
  object-fit: cover;
}

.WareHose-Right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
}

.WareHose-Right h3 {
  font-size: 33px;
  font-weight: 800;
  line-height: 1.4;
  margin-bottom: 1%;
}

.WareHose-Right h5 {
  line-height: 1.4;
  color: #3abef9;
  margin-bottom: 2%;
}

.WareHose-Right p,
.Ware_Housesection-2 p {
  color: #848383;
  line-height: 1.8;
  margin-top: 2%;
  text-align: justify;
}

.Ware_Housesection-2 {
  padding-left: 8%;
  padding-right: 8%;
}

.Ware_Housesection-2 h6 {
  margin-top: 3%;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

@media only screen and (max-width: 1200px) {
  .WareHose-Right h3 {
    font-size: 28px;
  }
  .WareHose-Right h5 {
    font-size: 18px;
  }
  .Ware_Housesection-2 h6 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 992px) {
  .WareHose-Right h3 {
    font-size: 25px;
  }
  .WareHose-Right h5 {
    font-size: 16px;
  }
  .Ware_Housesection-2 h6 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .WareHouse-container {
    flex-direction: column;
    align-items: center;
    padding: 2%;
    height: auto;
  }
  .WareHose-left {
    width: 100%;
  }
  .left-image {
    margin-top: 24%;
    width: 100%;
    height: auto;
  }
  .WareHose-Right {
    width: 100%;
    margin-top: 5%;
    padding: 1rem;
  }
  .WareHose-Right h3 {
    font-size: 22px;
  }
  .WareHose-Right h5 {
    font-size: 14px;
  }
  .WareHose-Right p,
  .Ware_Housesection-2 p {
    font-size: 14px;
  }
  .Ware_Housesection-2 {
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
  }
  .Ware_Housesection-2 h6 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .WareHose-Right h3 {
    font-size: 18px;
  }
  .labelling-h1 {
    margin-top: 10%;
  }
  .WareHose-Right h5 {
    margin-top: 12%;
    font-size: 12px;
  }
  .WareHose-Right p,
  .Ware_Housesection-2 p {
    font-size: 12px;
  }
  .Ware_Housesection-2 h6 {
    margin-top: 5%;
    text-align: left;
    font-size: 16px;
  }
}
