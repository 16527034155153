.mission-vision-container {
  margin-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 1200px;
}

.mission-section,
.vision-section,
.values-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
}

.mission-section {
  background-color: #b71c1c;
  color: white;
}

.vision-section {
  background-color: #ff9800;
  color: white;
}

.values-section {
  background-color: #03a9f4;
  color: white;
}

.mission-vision-container h2 {
  line-height: 1.4;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 1%;
}

.mission-vision-container p {
  line-height: 1.8;
  margin-top: 3%;
  text-align: justify;
}

.text {
  padding: 30px;
}

.VisonMissionimage:nth-child(0) {
  text-align: end;
}

.VisonMissionimage:nth-child(1) {
  text-align: start;
}

.VisonMissionimage:nth-child(2) {
  text-align: end;
}

.VisonMissionimage img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .mission-section,
  .vision-section,
  .values-section {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .text {
    padding: 20px;
  }

  .mission-vision-container h2 {
    font-size: 28px;
  }

  .mission-vision-container p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .text {
    padding: 10px;
  }

  .mission-vision-container h2 {
    font-size: 24px;
  }

  .mission-vision-container p {
    font-size: 14px;
  }
}
