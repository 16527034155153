.chat-icon-container {
  position: relative;
}

.Whatsapp,
.Message {
  z-index: 1;
  position: fixed;
  right: 10px;
}

.Whatsapp {
  bottom: 10px;
}

.Message {
  bottom: 70px;
}

.chat-icon {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.notification-dot {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
}

@media (max-width: 768px) {
  .Whatsapp,
  .Message {
    right: 10px;
  }

  .chat-icon {
    width: 40px;
    height: 40px;
  }

  .notification-dot {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .Whatsapp,
  .Message {
    right: 15px;
    bottom: 5px;
  }

  .Message {
    bottom: 55px;
  }

  .chat-icon {
    width: 35px;
    height: 35px;
  }

  .notification-dot {
    width: 12px;
    height: 12px;
    font-size: 8px;
  }
}
