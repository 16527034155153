.dynamic-button {
  margin-top: 7%;
  padding: 13px 40px 13px 18px;
  background-color: hsl(220, 68%, 54%);
  border: 1px solid hsl(220, 68%, 54%);
  color: #fff;
  position: relative;
  text-align: left;
  border-radius: 8px;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  line-height: 20px;
  min-width: 140px;
  transition: all 0.3s ease-out;
}
.dynamic-button:before,
.dynamic-button:after {
  content: url("../../assets/arrow-white.svg");
  display: inline-block;
  max-width: 10px;
  opacity: 1;
  position: absolute;
  right: 18px;
  top: 33%;
  transform: translate3d(0, -50%, 0);
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
  will-change: transform;
  animation: movearrow 1.5s ease infinite;
}

@keyframes movearrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
