.our-story-container {
  background: url("../../../assets/OurstoryImg.jpg");
  background-size: cover;
  height: 100vh;
  background-position: center;
  display: flex;
}

.our-story-content {
  flex: 1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-story-content h2 {
  background-image: linear-gradient(
    103deg,
    #52c5fa,
    40%,
    hsl(220, 68%, 54%) 100%
  );
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  font-size: 2.5rem;
}

.our-story-section {
  padding-left: 5%;
  margin-top: 3%;
  padding-right: 5%;
  display: flex;
  max-width: 1200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.OurStory-Left {
  flex: 1;
  padding: 20px;
}

.OurStory-Left h3 {
  line-height: 1.4;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 1%;
}

.OurStory-Left p {
  color: #848383;
  line-height: 1.8;
  margin-top: 3%;
  text-align: justify;
}

.OurStory-Right {
  flex: 1;
  padding: 20px;
}

.OurStory-Right img {
  max-width: 100%;
  height: auto;
}

.whole-section {
  max-width: 1200px;
}

@media (max-width: 768px) {
  .our-story-content h2 {
    font-size: 2rem;
  }

  .our-story-section {
    flex-direction: column;
    padding: 20px;
  }

  .OurStory-Left,
  .OurStory-Right {
    padding: 10px;
  }

  .OurStory-Left h3 {
    font-size: 22px;
  }

  .OurStory-Left p {
    font-size: 16px;
  }

  .OurStory-Right img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .our-story-content h2 {
    font-size: 1.5rem;
  }

  .OurStory-Left h3 {
    font-size: 20px;
  }

  .OurStory-Left p {
    font-size: 14px;
  }
}
