/* .service-container {
  width: 100%;
  padding: 20px;
  margin-top: 5%;
}

.service-section {
  background-image: url("../../../assets/wave2.svg");
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.service-section-2 {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.heading {
  margin-top: 2%;
  margin-bottom: 60px;
  text-align: center;
}

.heading h2 {
  font-size: 2em;
  color: #fff;
  font-weight: 700;
}

.row,
.row-2 {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}

.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 10px;
  flex: 1;
  max-width: 450px;
  min-width: 300px;
  padding: 30px;
  position: relative;
  z-index: 3;
}

.card h2 {
  font-family: proxima-nova, helvetica, arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #333e49;
  line-height: 3.6rem;
  margin-bottom: 16px;
}

.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.card h3 {
  font-size: 1.5em;
  margin: 15px 0;
}

.card p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: justify;
  color: #848383;
}
.dropdown__icon {
  background-color: hsl(220, 68%, 97%);
  border-radius: 25px;
  padding: 5px;
  text-align: center;
  width: 50px;
  height: 50px;
}
.dropdown__icon i {
  font-size: 30px;
  color: hsl(220, 68%, 54%);
}
.styles_divider__line {
  background: rgb(242, 244, 245);
  height: 1px;
  margin: 16px auto 24px;
  width: 100%;
}
.ul-element li {
  position: relative;
  font-size: 14px;
  line-height: 1.9;
  left: 4%;
  color: #848383;
}

.ul-element li::before {
  background-color: hsl(220, 68%, 54%);
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 8px;
  left: -8px;
  margin-right: 2px;
  position: relative;
  top: -2px;
  width: 8px;
} */

.service-container {
  width: 100%;
  padding: 20px;
  margin-top: 5%;
}

.service-section {
  background-image: url("../../../assets/wave2.svg");
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.service-section-2 {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.heading {
  margin-top: 2%;
  margin-bottom: 60px;
  text-align: center;
}

.heading h2 {
  font-size: 2em;
  color: #fff;
  font-weight: 700;
}

.row,
.row-2 {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
}

.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 10px;
  flex: 1;
  max-width: 450px;
  min-width: 300px;
  padding: 30px;
  position: relative;
  z-index: 3;
}

.card h2 {
  font-family: proxima-nova, helvetica, arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #333e49;
  line-height: 3.6rem;
  margin-bottom: 16px;
}

.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.card h3 {
  font-size: 1.5em;
  margin: 15px 0;
}

.card p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: justify;
  color: #848383;
}

.dropdown__icon {
  background-color: hsl(220, 68%, 97%);
  border-radius: 25px;
  padding: 5px;
  text-align: center;
  width: 50px;
  height: 50px;
}

.dropdown__icon i {
  font-size: 30px;
  color: hsl(220, 68%, 54%);
}

.styles_divider__line {
  background: rgb(242, 244, 245);
  height: 1px;
  margin: 16px auto 24px;
  width: 100%;
}

.ul-element li {
  position: relative;
  font-size: 14px;
  line-height: 1.9;
  left: 4%;
  color: #848383;
}

.ul-element li::before {
  background-color: hsl(220, 68%, 54%);
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 8px;
  left: -8px;
  margin-right: 2px;
  position: relative;
  top: -2px;
  width: 8px;
}

@media (max-width: 768px) {
  .service-section {
    padding: 30px 10px;
  }

  .heading h2 {
    font-size: 1.5em;
  }

  .card {
    max-width: 100%;
    padding: 20px;
  }

  .card h2 {
    font-size: 1.5em;
  }

  .card p {
    font-size: 14px;
  }

  .ul-element li {
    font-size: 12px;
  }

  .dropdown__icon {
    width: 40px;
    height: 40px;
  }

  .dropdown__icon i {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .service-section {
    padding: 20px 5px;
  }

  .heading h2 {
    font-size: 1.2em;
  }

  .card {
    padding: 15px;
  }

  .card h2 {
    font-size: 1.2em;
  }

  .card p {
    font-size: 12px;
  }

  .ul-element li {
    font-size: 10px;
  }

  .dropdown__icon {
    width: 30px;
    height: 30px;
  }

  .dropdown__icon i {
    font-size: 18px;
  }
}
