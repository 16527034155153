.business-container {
  display: flex;
  padding-left: 6%;
  flex-direction: column;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.left,
.right {
  flex: 1;
}

.left img,
.right img {
  width: 80%;
  border-radius: 20px;
  height: auto;
}

.business-container p {
  color: #848383;
  line-height: 2;
  margin: 30px 70px 20px 0px;
  text-align: justify;
}

.business-container h2 {
  font-size: 28px;
  color: hsl(220, 48%, 28%);
  font-weight: 600;
}

.business-container h4 {
  margin-top: 1.5%;
  line-height: 1.3;
  width: 85%;
  font-size: 28px;
}

@media (max-width: 1200px) {
  .business-container {
    padding-left: 2%;
  }

  .section {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .left img,
  .right img {
    width: 90%;
  }

  .business-container p {
    margin: 20px 50px 20px 0px;
  }

  .business-container h2,
  .business-container h4 {
    font-size: 24px;
  }
}

@media (max-width: 992px) {
  .business-container {
    padding-left: 6%;
    padding-right: 5%;
  }

  .section {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right img:nth-child(1) {
    margin-top: 5%;
  }

  .business-container p {
    margin: 15px 30px 15px 0px;
  }

  .business-container h2,
  .business-container h4 {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
    align-items: flex-start;
    /* margin-bottom: 20px; */
  }

  .left,
  .right {
    flex: none;
    width: 100%;
  }

  .left img,
  .right img {
    width: 100%;
    height: auto;
  }

  .business-container p {
    color: #718096;
    line-height: 1.6;
    margin: 10px 0;
    text-align: justify;
  }

  .business-container h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .business-container h2:nth-child(1) {
    margin-top: 5%;
  }
  .business-container h4 {
    font-size: 18px;
  }
}
