/* .air-freight {
  margin: 110px auto;
  padding-left: 5%;
  padding-right: 5%;
}

.air-freight-text h1 {
  position: relative;
  left: 4%;
  width: 45%;
  font-size: 33px;
  font-weight: 800;
  line-height: 1.5;
  margin-bottom: 1%;
}

.image-container {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.image-card {
  overflow: visible;
  border-radius: 8px;
  position: relative;
}

.image-card img {
  width: 100%;
  object-fit: cover;
}
.image-card:nth-child(1) {
  margin-top: 8%;
}
.image-card:nth-child(3) {
  margin-top: 8%;
}

.image-card:nth-child(2)::after {
  background: url("../../../assets/Fill\ 1.png") no-repeat;
  background-size: contain;
  content: "";
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.footer-text {
  position: absolute;
  bottom: -270px;
  padding-right: 8%;
  right: 20px;
  width: 50%;
}

.footer-text p {
  color: #848383;
  line-height: 1.8;
  margin-bottom: 5px;
  text-align: justify;
} */

.air-freight {
  margin: 110px auto;
  padding-left: 5%;
  padding-right: 5%;
}

.air-freight-text h1 {
  position: relative;
  left: 4%;
  width: 45%;
  font-size: 33px;
  font-weight: 800;
  line-height: 1.5;
  margin-bottom: 1%;
}

.image-container {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.image-card {
  overflow: visible;
  border-radius: 8px;
  position: relative;
}

.image-card img {
  width: 100%;
  object-fit: cover;
}

.image-card:nth-child(1) {
  margin-top: 8%;
}

.image-card:nth-child(3) {
  margin-top: 8%;
}

.image-card:nth-child(2)::after {
  background: url("../../../assets/Fill 1.png") no-repeat;
  background-size: contain;
  content: "";
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.footer-text {
  position: absolute;
  bottom: -270px;
  padding-right: 8%;
  right: 20px;
  width: 50%;
}

.footer-text p {
  color: #848383;
  line-height: 1.8;
  margin-bottom: 5px;
  text-align: justify;
}

.paragh p {
  color: #848383;
  line-height: 1.8;
  text-align: justify;
  margin-top: 3%;
  padding-left: 8%;
  padding-right: 9%;
}

/* Media queries for responsiveness */
@media only screen and (max-width: 1200px) {
  .air-freight-text h1 {
    font-size: 28px;
    width: 60%;
  }
  .image-container {
    gap: 40px;
  }
  .footer-text {
    width: 60%;
  }
}

@media only screen and (max-width: 992px) {
  .air-freight-text h1 {
    font-size: 25px;
    width: 70%;
  }
  .image-container {
    gap: 30px;
  }
  .footer-text {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .air-freight-text h1 {
    font-size: 22px;
    width: 80%;
    left: 0;
  }
  .image-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .image-card:nth-child(1),
  .image-card:nth-child(3) {
    margin-top: 0;
  }
  .image-card:nth-child(2)::after {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .footer-text {
    position: relative;
    bottom: auto;
    right: auto;
    width: 90%;
    padding-right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .air-freight-text h1 {
    font-size: 18px;
    width: 100%;
    margin-bottom: 5%;
  }
  .image-container {
    gap: 10px;
  }
  .image-card:nth-child(2),
  .image-card:nth-child(3) {
    display: none;
  }
  .footer-text {
    margin-top: 8%;

    width: 100%;
  }
  .paragh p {
    padding-left: 5%;
    padding-right: 5%;
  }
}
