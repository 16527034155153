@import url("https://fonts.googleapis.com/css2?family=Playwrite+NG+Modern:wght@100..400&display=swap");

.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  cursor: pointer;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  z-index: 0;
}

.video-overlay h1 {
  color: #fff;
  cursor: pointer;
  font-size: 60px;
  line-height: 1.3;
}

@media (max-width: 1200px) {
  .video-overlay h1 {
    font-size: 50px;
  }
}

@media (max-width: 992px) {
  .video-overlay h1 {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .video-overlay h1 {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .video-overlay h1 {
    font-size: 20px;
  }
}
