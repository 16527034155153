.logistics-container {
  padding-left: 5%;
  padding-right: 5%;
}

.logistics-container h1 {
  margin-top: 2%;
  padding-left: 3%;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

.logistics-container p {
  color: #848383;
  line-height: 1.8;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 2%;
  text-align: justify;
}

.logistics-content {
  display: flex;
  justify-content: center;
  gap: 40px;
  line-height: 2;
  margin-top: 20px;
}

.left-side,
.right-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right-side {
  position: relative;
}

.logistics-image img {
  width: 450px;
  margin-bottom: 20px;
}

.logistics-items,
.logistics-items-rightside {
  display: flex;
  line-height: 1.9;
  flex-direction: column;
  align-items: flex-start;
}

.logistics-items-rightside {
  position: relative;
  top: 20%;
}

.logistics-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.logistics-number {
  background-color: #3abef9;
  color: white;
  border-radius: 50%;
  width: 20px;
  padding: 3%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
}

.logistics-item h2 {
  margin: 0 10px 0 0;
  font-size: 1em;
}

.logistics-item p {
  margin: 0;
  margin-bottom: 1%;
  color: #848383;
}

@media only screen and (max-width: 1200px) {
  .logistics-content {
    gap: 20px;
  }

  .logistics-image img {
    width: 350px;
  }

  .logistics-number {
    width: 18px;
    height: 28px;
    padding: 2%;
  }

  .logistics-item h2 {
    font-size: 0.9em;
  }

  .logistics-item p {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 992px) {
  .logistics-content {
    flex-direction: column;
    align-items: center;
  }

  .logistics-image img {
    width: 300px;
  }

  .logistics-items-rightside {
    top: 0;
  }

  .logistics-number {
    width: 16px;
    height: 26px;
    padding: 2%;
  }

  .logistics-item h2 {
    font-size: 0.85em;
  }

  .logistics-item p {
    font-size: 0.85em;
  }
}

@media only screen and (max-width: 768px) {
  .logistics-container {
    padding-left: 3%;
    padding-right: 3%;
  }

  .logistics-content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .logistics-image img {
    width: 250px;
  }

  .logistics-number {
    width: 14px;
    height: 24px;
    padding: 1%;
  }

  .logistics-item h2 {
    font-size: 0.8em;
  }

  .logistics-item p {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 576px) {
  .logistics-container {
    padding-left: 2%;
    padding-right: 2%;
  }

  .logistics-container h1 {
    margin-top: 5%;
    font-size: 16px;
  }

  .logistics-container p {
    font-size: 0.8em;
  }

  .logistics-content {
    gap: 5px;
  }

  .logistics-image img {
    width: 350px;
  }

  .logistics-number {
    width: 28px;
    height: 22px;
    padding: 1%;
  }

  .logistics-item h2 {
    font-size: 0.75em;
  }

  .logistics-item p {
    font-size: 0.75em;
  }
}
